<template>
  <v-card
    flat>
    <v-card-subtitle>Find Data</v-card-subtitle>
    <v-card-text class="px-5">
      <v-row>
        <v-menu
          v-model="startDate"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px">
          <template #activator="{ on, attrs }">
            <v-text-field
              class="mr-2"
              v-model="computedDateFormatted"
              dense
              label="Date Start"
              hint="DD/MM/YYYY format"
              persistent-hint
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on" />
          </template>
          <v-date-picker
            v-model="dateStart"
            no-title
            @input="startDate = false" />
        </v-menu>
        <v-menu
          v-model="endDate"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px">
          <template #activator="{ on, attrs }">
            <v-text-field
              class="mr-2"
              v-model="computedDateEndFormatted"
              dense
              label="Date Start"
              hint="DD/MM/YYYY format"
              persistent-hint
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on" />
          </template>
          <v-date-picker
            v-model="dateEnd"
            no-title
            @input="endDate = false" />
        </v-menu>

        <v-combobox
          class="mr-2"
          v-model="dayInfo.tel_type"
          :items="telType"
          item-text="keys"
          item-value="value"
          label="TEL TYPE"
          outlined
          dense></v-combobox>

        <v-combobox
          class="mr-2"
          v-model="dayInfo.device_type"
          :items="devices"
          item-text="name"
          item-value="value"
          label="Platform Type"
          outlined
          dense></v-combobox>
        
        <v-btn
          color="primary"
          outlined
          :loading="loadData"
          :disabled="loadData"
          @click="findData">
          <v-icon class="mr-2">mdi-clipboard-text-search</v-icon>
          SEARCH
        </v-btn>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import * as moment from 'moment';

export default {
  name: 'findCondition',
  props: {
    dayInfo: {
      type: Object
    },
    loadData: false
  },

  data () {
    return {
      telType: [
        { keys: 'ALL', value: 'A' },
        { keys: 'Prepaid', value: 'P' },
        { keys: 'Postpaid', value: 'T' }
      ],
      devices: [{ name: 'All Platform', value: '' }, { name:'Web', value: 'web' }, { name:'Native App', value: 'native' }, { name: 'Android (App Only)', value: 'android' }, { name: 'iOS (App Only)', value: 'ios' }],
      startDate: false,
      endDate: false,
      dateStart: moment().format('YYYY-MM-DD'),
      dateEnd: moment().format('YYYY-MM-DD')
    }
  },

  computed: {
    computedDateFormatted() {
      this.dayInfo.start_date = new Date(this.dateStart).getTime();
      return this.formatDate(this.dateStart);
    },

    computedDateEndFormatted() {
      this.dayInfo.start_end = new Date(this.dateEnd).getTime();
      return this.formatDate(this.dateEnd);
    },
  },

  methods: {
    findData() {
      // eslint-disable-next-line no-undef
      eventBus.$emit('findData', this.dayInfo);
    },

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split('-');
      return `${day}/${month}/${year}`;
    },
  }
}
</script>